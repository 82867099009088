/* Content Cropper */

.img-container,
.img-preview {
    background-color: #f7f7f7;
    width: 100%;
    text-align: center;
}

.img-container {
    min-height: 200px;
    max-height: 516px;
    margin-bottom: 20px;
}

@media (min-width: 768px) {
    .img-container {
        /*min-height: 516px;*/
        min-height: 410px;
    }
}

.img-container > img {
    max-width: 100%;
}

.docs-preview {
    margin-right: -15px;
}

.img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

.img-preview > img {
    max-width: 100%;
}

.preview-lg {
    width: 263px;
    height: 148px;
}

.preview-md {
    width: 139px;
    height: 78px;
}

.preview-sm {
    width: 69px;
    height: 39px;
}

.preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0;
}

.docs-data > .input-group {
    margin-bottom: 10px;
}

.docs-data > .input-group > label {
    min-width: 80px;
}

.docs-data > .input-group > span {
    min-width: 50px;
}

.docs-buttons > .btn,
.docs-buttons > .btn-group,
.docs-buttons > .form-control {
    margin-right: 5px;
    margin-bottom: 10px;
}

.docs-toggles > .btn,
.docs-toggles > .btn-group,
.docs-toggles > .dropdown {
    margin-bottom: 10px;
}

.docs-tooltip {
    display: block;
    margin: -6px -12px;
    padding: 6px 12px;
}

.docs-tooltip > .icon {
    margin: 0 -3px;
    vertical-align: top;
}

.tooltip-inner {
    white-space: normal;
}

.btn-upload .tooltip-inner,
.btn-toggle .tooltip-inner {
    white-space: nowrap;
}

.btn-toggle {
    padding: 6px;
}

.btn-toggle > .docs-tooltip {
    margin: -6px;
    padding: 6px;
}

@media (max-width: 400px) {
    .btn-group-crop {
        margin-right: -15px!important;
    }

    .btn-group-crop > .btn {
        padding-left: 5px;
        padding-right: 5px;
    }

    .btn-group-crop .docs-tooltip {
        margin-left: -5px;
        margin-right: -5px;
        padding-left: 5px;
        padding-right: 5px;
    }
}

.docs-options .dropdown-menu {
    width: 100%;
}

.docs-options .dropdown-menu > li {
    padding: 3px 20px;
}

.docs-options .dropdown-menu > li:hover {
    background-color: #f7f7f7;
}

.docs-options .dropdown-menu > li > label {
    display: block;
}

.docs-cropped .modal-body {
    text-align: center;
}

.docs-cropped .modal-body > img,
.docs-cropped .modal-body > canvas {
    max-width: 100%;
}

.docs-diagram .modal-dialog {
    max-width: 352px;
}