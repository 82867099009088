h1, h2, h3, h4, h5 {
    font-weight: bold;
}


.navbar-default {
    box-shadow: none;
    background-image:none;
    background-color: #eaeaea;
}
.navbar-default .container {
}

.navbar-default .dropdown-menu {
    background-color: #eaeaea;
}

.btn:focus, a:focus {
    outline: none;
}

.fade {
    opacity: 0;
    -webkit-transition: opacity .05s linear;
    transition: opacity .05s linear;
}

.breadcrumb {
    background-color: #FFFFFF;
}

.secondary-nav {
    background-color: #f5f5f5;
    margin-bottom: 20px;
}
.secondary-nav li a img {
    width: 36px;
    height: 36px;
    padding:2px;
}
.secondary-nav li.active a img {
    border-radius: 50%;
    border:2px solid orange;
}
.secondary-nav li.active a {
    background-color: #e1e1e1;
}
.secondary-nav li.active a:hover {
    background-color: #e1e1e1;
}

.employee-img {
    width:150px;
}
.employee-img-small {
    width:50px;
}

.message-img-small {
    width: 24px;
}

.img-circle {
    border-radius: 50%;
}
a img.img-circle {
    border:4px solid white;
}
a img.img-circle:hover {
    border:4px solid orange;
}

.pic {
    height: 160px;
    width: 160px;
    overflow: hidden;
    text-align: center;
}

.small-pic {
    height: 20px;
    width: 20px;
    overflow: hidden;
    text-align: center;
}

/*TILT*/
.tilt {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.tilt:hover {
    -webkit-transform: rotate(-10deg);
    -moz-transform: rotate(-10deg);
    -o-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
}

/*GROW*/
.grow img {
    height: 128px;
    width: 128px;

    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    -ms-transition: all 0.1s ease;
    transition: all 0.1s ease;
}

.grow img:hover {
    width: 160px;
    height: 160px;
}


.logoCircle {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    padding: 8px;
    background: gray;
    border: 2px solid white;
    color: white;
    text-align: center;
    font: 32px Roboto, sans-serif;
}

.logoCircle-35 {
     width: 35px;
     height: 35px;
     font-size: 12px;
 }
.logoCircle-70 {
    width: 70px;
    height: 70px;
    font-size: 38px;
}


table tr.generated {
    background-color: lightyellow;
}

input.has-error, textarea.has-error, select.has-error, .has-error .select2-container--default .select2-selection--multiple  {
    border-color: #a94442;
}

label {
    font-weight: 100 !important;
}

.has-error label.control-label, .required label.control-label, .required label.rcontrol-label {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 700 !important;
}
.has-error label.rcontrol-label {
    color: #a94442;
}

td.number-of-days{
    vertical-align: middle !important;
    text-align: center;
}

.employee-type-1{
    background-color: forestgreen;
}
.employee-type-2 {
    background-color: navy;
}
.employee-type-3 {
    background-color: dodgerblue;
}
.employee-type-4 {
    background-color: coral;
}

.employee-status-20, .employee-status-30 {
    background-color: darkgray;
}

.contract-status-ACTIVE {
    background-color: green;
}
.contract-status-DRAFT {
    background-color: darkgray;
}
.contract-status-EXPIRED {
    background-color: black;
}

.job-status-ACTIVE {
    background-color: #dcefe3;
}

.job-status-DRAFT {
    background-color: lightyellow;
}

.job-status-EXPIRED {
    background-color: #eaeaea;
    color: darkgray;
}


.holiday-type-20 {
    background-color:#fbf8df;
}

.table-striped>tbody>tr.holiday-type-20:nth-of-type(odd) {
    background-color: #f5f2d9;
}


/* employee avatar status inactive (terminated & resigned) */
.employee-avatar-status-inactive {
    -webkit-filter: grayscale(1);
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: grayscale(100%);
}
/* /employee avatar status inactive */

a.opp-stage:hover {
    background-color: black;
}

.opp-stage {
    font-size: 12px;
}

.opp-stage-done {
    background-color: #64a25b;
}

.opp-stage-dis {
    background-color: #a2a2a2;
}
.opp-stage-0 {
    background-color: gray;
}
.opp-stage-10 {
    background-color: yellowgreen;
}
.opp-stage-15 {
    background-color: orange;
}
.opp-stage-20 {
    background-color: dodgerblue;
}
.opp-stage-25 {
    background-color: navy;
}
.opp-stage-30 {
    background-color: coral;
}
.opp-stage-50 {
    background-color: forestgreen;
}
.opp-stage-60 {
    background-color: orangered;
}

.proc-stage-0 {
    background-color: gray;
}
.proc-stage-10 {
    background-color: yellowgreen;
}
.proc-stage-15 {
    background-color: orange;
}
.proc-stage-20 {
    background-color: dodgerblue;
}
.proc-stage-25 {
    background-color: navy;
}
.proc-stage-30 {
    background-color: coral;
}
.proc-stage-50 {
    background-color: forestgreen;
}
.proc-stage-60 {
    background-color: orangered;
}
.proc-stage-100 {
    background-color: orangered;
}

a.project-stage:hover {
    background-color: black;
}

.project-stage {
    font-size: 12px;
}

.project-stage-done {
    background-color: #64a25b;
}

.project-stage-dis {
    background-color: #a2a2a2;
}
.project-stage-0 {
    background-color: gray;
}
.project-stage-10 {
    background-color: yellowgreen;
}
.project-stage-15 {
    background-color: orange;
}
.project-stage-20 {
    background-color: dodgerblue;
}
.project-stage-25 {
    background-color: navy;
}
.project-stage-30 {
    background-color: coral;
}
.project-stage-40 {
    background-color: coral;
}

.project-stage-50 {
    background-color: forestgreen;
}
.project-stage-60 {
    background-color: orangered;
}

.project-status-0 {
    background-color: grey;
}

.project-status-10 {
    background-color: forestgreen;
}
.project-status-20 {
    background-color: orangered;
}
.project-status-30 {
    background-color: navy;
}
.project-status-40 {
    background-color: black;
}

.account-status-1 {
    background-color: forestgreen;
}
.account-status-0 {
    background-color: orangered;
}

.interview-status, .candidate-status {
    background-color: gray;
}

.interview-status-1 {
    background-color: forestgreen;
}
.interview-status-2 {
    background-color: orangered;
}

.interview-status-3 {
    background-color: navy;
}

.invoice-status-10, .invoice-status-draft {
    background-color: grey;
}
.invoice-status-20, .invoice-status-send {
    background-color: navy;
}
.invoice-status-30, .invoice-status-paid {
    background-color: forestgreen;
}
.invoice-status-40, .invoice-status-unpaid {
    background-color: orangered;
}
.invoice-text-color-status-10, .invoice-text-color-status-draft {
    color: grey;
}
.invoice-text-color-status-20, .invoice-text-color-status-send {
    color: navy;
}
.invoice-text-color-status-30, .invoice-text-color-status-paid {
    color: forestgreen;
}
.invoice-text-color-status-40, .invoice-text-color-status-unpaid {
    color: orangered;
}
.invoice-status-write-off {
    background-color: black;
}

/* estimate statuses */
.estimate-status-10, .estimate-status-draft {
    background-color: grey;
}
.estimate-status-20, .estimate-status-send {
    background-color: navy;
}
.estimate-status-30, .estimate-status-open {
    background-color: forestgreen;
}
.estimate-status-40, .estimate-status-approved {
    background-color: forestgreen;
}
.estimate-status-50, .estimate-status-rejected {
    background-color: orangered;
}
.estimate-status-60, .estimate-status-invoiced {
    background-color: navy;
}
.estimate-text-color-status-10, .estimate-text-color-status-draft {
    color: grey;
}
.estimate-text-color-status-20, .estimate-text-color-status-send {
    color: navy;
}
.estimate-text-color-status-30, .estimate-text-color-status-open {
    color: forestgreen;
}
.estimate-text-color-status-40, .estimate-text-color-status-approved {
    color: forestgreen;
}
.estimate-text-color-status-50, .estimate-text-color-status-rejected {
    color: orangered;
}
.estimate-text-color-status-60, .estimate-text-color-status-invoiced {
    color: navy;
}

/* employee holiday statuses */
.employee-holiday-status--1 {
    background-color: orangered;
}
.employee-holiday-status-0 {
    background-color: grey;
}
.employee-holiday-status-1 {
    background-color: forestgreen;
}
/* /employee holiday statuses */

.candidate-status-20 {
    background-color: orangered;
}


.thumb-clean {
    border:none;
    box-shadow: none;
}

.progress { border: 0px; height: 5px;}


tr.stage-50  {
    background-color: rgba(131, 219, 131, 0.28) !important;
}
tr.stage-60 {
    background-color: rgba(219, 106, 103, 0.18) !important;
}

.note {
    padding:10px 10px 5px 10px;
    background-color: lightyellow;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    margin-bottom: 10px;
    width: 100%;
}

.note pre {
    background-color: transparent;
    border: 1px solid transparent;
    overflow: auto;
    word-wrap: normal;
    white-space: pre;
    white-space:pre-wrap;
    word-break: normal;
}

.note:hover {
    background-color: white;
}

.note pre:hover {
    border: 1px solid #e9e9e9;
}

.note textarea {
    padding: 5px;
    box-sizing: border-box;

}

.note small {
    color: #b1b1b1;
    font-size: 9px;
}

.preformatted {
    width: 100%;
    white-space: pre;
    overflow: auto;
    word-wrap: normal;
    white-space: pre;
    white-space:pre-wrap;
    word-break: normal;

}

.flag-red {
    color:#c12e2a;
}
.flag-white {
    color:white;
}
.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
}

.btn:focus {
    outline: none !important;
}

i.fa-round-border {
    display: inline-block;
    border-radius: 60px;
    box-shadow: 0px 0px 2px #888;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; /* remove the gap so it doesn't close */
}
.dropdown:hover .dropdown-toggle {
    background-color: #ccc !important;
}

.category-permissions-row-color {
    background-color: #fff2cc !important;
}


.wiki-navigation {
    padding-left: 20px;
    font-size: 12px;
}

.wiki-navigation li {
    margin-top: 4px;
}

.secondary-nav hr {
    margin-top: 25px;
}

h3.wiki-header {
    margin-top: 10px;
}

.wiki-header-hr {
    margin-top: 10px;
}

.simple-link {
    outline: none;
    padding: 5px;
    border: 0;
    background: transparent none;
    box-shadow: none;
    text-shadow: none;
    color: #337ab7;
}

.simple-link:hover {
    outline: none;
    padding: 5px;
    border: 0;
    background: transparent none;
    box-shadow: none;
    text-shadow: none;
    text-decoration: underline;
    color: #23527c;
}

.wiki-nav-active {
    font-weight: bold;
}

.billing-bordered {
    padding: 10px;
    margin: 10px;
    border: 1px solid #cccccc;
}

.billing-note {
    margin: 10px 10px 15px;
    color: #888;
}

.settings-note {
    margin: 0 0 20px;
    color: #888;
}

.last-updated {
    color: #888;
    font-size: 8pt;
}

.bank-accounts input[type="checkbox"] {
    width: auto !important;
}

.board-margin-top {
    vertical-align: text-top;
}

.inline {
    display: inline;
}

.board-header-view {
    width: 80%;
    float: left;
}

.board-header-view p {
    margin-top: 5px;
    margin-bottom: 0;
}

.badge-new {
    background: #5FA458;
}

.badge-no-new {
    background: #CCCCCC;
}

.bold {
    font-weight: bold;
}

.white-bg {
    background: #ffffff;
}

.invoice-text-label {
    font-size: 9pt;
}

.no-margin-top {
    margin-top: 0;
}

.inline-invoice-form-item {
    display: inline;
    width: auto;
    padding: 2px 2px 2px 4px;
}

.inline-invoice-form-item-qty {
    width: 30%;
}

.inline-invoice-form-item-type {
    width: 66%;
}

.inline-invoice-form-item-up {
    width: 100%;
}

.table-row-form-bg {
    background: #FFFFC8 !important;
}

body.dragging, body.dragging * {
    cursor: move !important;
}

.dragged {
    position: absolute;
    opacity: 0.5;
    z-index: 2000;
}

.sorted-table tr {
    cursor: pointer;
}

.sorted-table tr.placeholder {
    display: block; background : #C12E2A; position : relative; margin : 0; padding : 0; border : none;
}

.sorted-table tr.placeholder:before {
    content: "";
    position : absolute;
    width : 0;
    height : 0;
    border : 5px solid transparent;
    border-left-color : #C12E2A;
    margin-top : -5px;
    left : -5px;
    border-right : none;
}

.vertical-align {
    display: flex;
    align-items: center;
}

.margin-top-10px {
    margin-top: 10px;
}

.columns-to-center {
    float: none;
    display: inline-block;
}

.image-35px {
    height: 35px;
    width: 35px;
    overflow: hidden;
    text-align: center;
}

.image-25px {
    height: 25px;
    width: 25px;
    overflow: hidden;
    text-align: center;
}

.table.vertical-align-cols tbody>tr>td {
    vertical-align: middle;
}

/* If don't want to view preview files from DROP ZONE section */
.dz-preview {
    display: none;
}

.issue-drop-zone {
    padding: 45px;
    border-radius: 0px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px dashed #ccc;
    transition: background-color .01s linear .01s;
}

.hidden-by-default {
    display: none;
}

.issue-drop-zone.dz-drag-hover {
    border: 1px dashed #000;
    background-color: lightyellow;
}

.am-progress {
    height: 20px;
}

.drop-zone-upload-loading {
    padding: 45px;
    border-radius: 0px;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px dashed #000;
    background-color: lightyellow;
    transition: background-color .01s linear .01s;
}

.am-loading-process {
    height: 480px;
    border-radius: 0px;
    border: 1px dashed #000;
    background-color: lightyellow;
    transition: background-color .01s linear .01s;
}
.am-loading-process img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.table .thead-background-black {
    background-color: #000000;
    color: #ffffff;
}

.view-invoice {
    font-family: "Arial";
}

.view-invoice .view-invoice-content-table {
    font-size: 12px !important;
}

.font-size-14px {
    font-size: 14px;
}

.white-background-color {
    background-color: #ffffff;
}

.vertical-align-center {
    top: 50%;
    transform: translateY(50%);
}

.table .table-border-top-white {
    border-top: 1px solid #fff;
}

.table.table-content-align-center-vertical > tbody > tr > td {
    vertical-align: middle;
}

.table .total-row {
    border-top: 2pt solid black;
}

.table-condensed {
    font-size:12px;
}

.display-table-caption {
    display: table-caption;
}

/* these styles used when user wants to print something */
@media print {
    .table .thead-background-black {
        background-color: #000000 !important;
        color: #ffffff;
    }
}

.calendar-reload-events {
    transition: opacity .75s ease;
    -moz-transition: opacity .75s ease;
    -webkit-transition: opacity .75s ease;
    opacity: 0.25;
}
.calendar-reloaded-events {
    transition: opacity .75s ease;
    -moz-transition: opacity .75s ease;
    -webkit-transition: opacity .75s ease;
    filter: alpha(opacity=100);
    opacity: 1;
}

.without-border {
    border: none;
}

.col-small-padding {
    padding-left: 3px;
    padding-right: 3px;
}

.form-control-small-padding {
    padding-left: 5px;
    padding-right: 5px;
}

.is-disabled {
    opacity: 0.5;
    pointer-events: none;
    text-decoration: none;
    cursor: not-allowed;
}

.navbar.navbar-default {
    margin-bottom: 0;
}

.events-notification {
    margin-bottom: 20px;
    background-color: #fff0c3;
    position: relative;
}

.events-notification-content {
    margin-top: 10px;
    margin-bottom: 10px;
}

.events-band:hover {
    opacity: 0.7;
}

.overdue-bg {
    background-color: #fdd8be;
    position: absolute;
    right: 0;
    height: 100%;
}

.events-notification-icon {
    width: 34px;
    height: 34px;
}

.events-notification-band .events-notification-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
}

.events-notification-band h3 {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    margin: 8px;
    color: #d69a00;
}

.events-notification-band h3 .red {
    color: #d75c0c;
}

.events-notification-list .view-more {
    margin: 16px 8px 8px 8px;
}

.events-notification-list .view-more a {
    font-size: 12px;
    color: #d69a00;
}

.events-notification-list.overdue-list .view-more a {
    color: #d75c0c;
}

.events-notification-list h3 {
    font-size: 14px;
    font-weight: bold;
    margin: 8px 8px 16px 8px;
    color: #d69a00;
}

.events-notification-list.overdue-list h3 {
    color: #d75c0c;
}

.events-notification-list table {
    width: 100%;
    margin: 8px;
}

.events-notification-list table .title {
    text-align: left;
    color: #d69a00;
    font-size: 12px;
}

.events-notification-list table .title a {
    color: #65afff;
    text-decoration: underline;
    width: 400px;
}

.events-notification-list table .due {
    color: #e9ca7b;
    font-size: 12px;
    white-space: nowrap;
    width: 100px;

}

.events-notification-list.overdue-list table .title {
    color: #d75c0c;
}

.events-notification-list.overdue-list table .title a {
    color: #65afff;
    text-decoration: underline;
}

.events-notification-list.overdue-list table .due {
    color: #e39868;
}

.lg-popover {
    max-width: 100%;
}

.del {
    text-decoration: line-through;
}

.display-block {
    display: block;
}
.events-notification-list table {
    font-size:11px;
}

.events-notification-content table {
    border-top:1px solid white;
    font-size: 11px;
}
.events-notification-content table > thead > tr > th{
    border-top:1px solid white;
}
.events-notification-content table > tbody > tr > td{
    border-top:1px solid white;
}

.my-events-timeline h4 {
    border-bottom: 1px solid #f0f0f0;
    font-size: 18px;
    color: #222d2f;
    padding: 8px 0;
    margin-bottom: 15px;
    font-weight: bold;
}

.my-events-timeline .timeline-item {
    padding: 6px 0;
}

.my-events-timeline .timeline-item .edit-event-timeline {
    display: none;
}

.my-events-timeline .timeline-item:hover .edit-event-timeline {
    display: inline;
}

.my-events-timeline .timeline-item label {
    font-size: 12px;
    color: #222d2f;
}

.my-events-timeline .timeline-item .small-till {
    font-size: 12px;
    color: #d69a00;
    padding-left: 30px;
}

.my-events-timeline .timeline-item a {
    text-decoration: underline;
    font-size: 12px;
}

.my-events-timeline .timeline-item .avatar-icons {
    float: left;
    cursor: default;
}

.my-events-timeline .timeline-item .avatar-icons:hover {
    opacity: 0.7;
}

.my-events-timeline .timeline-item .avatar-icons .avatar-ico {
    width: 16px;
    float: left;
}

.my-events-timeline .timeline-item .avatar-icons .avatar-ico img {
    width: 24px;
    border: 1px solid white;
}

.my-events-timeline .timeline-item .avatar-icons .avatar-ico img.red-circle {
    border: 2px solid #d75c0c;
}

.my-events-timeline .timeline-item .avatar-icons .avatar-label {
    margin-left: 16px;
    float: left;
    margin-top: 4px;
    font-size: 12px;
    color: #aeaeae;
}

.my-events-timeline .overdue {
    background: #fdd8be;
}

.my-events-timeline .overdue h4 {
    color: #d75c0c;
    border-bottom: 1px solid #d75c0c;
}

.my-events-timeline .overdue .timeline-item label {
    color: #d75c0c;
}

.my-events-timeline .overdue .timeline-item .small-till {
    color: #d75c0c;
}

.my-events-timeline .overdue .timeline-item .avatar-icons .avatar-label {
    color: #d75d0c8a;
}

.my-events-timeline .overdue .timeline-item .avatar-icons .avatar-ico img {
    border: 1px solid #fdd8be;
}

.my-events-timeline .overdue .timeline-item .avatar-icons .avatar-ico img.red-circle {
    border: 2px solid #d75c0c;
}
  
.material-checkbox label {
    padding-left: 30px
}

.material-checkbox input[type="checkbox"] {
    display: none
}

.material-checkbox input[type="checkbox"] + .box:before,
.material-checkbox input[type="checkbox"] + .box:after {
    transition: all .3s
}

.material-checkbox input[type="checkbox"] + .box:after {
    position: absolute;
    left: 18px;
    top: 7px;
    margin-top: -5px;
    margin-left: -4px;
    display: inline-block;
    content: ' ';
    width: 16px;
    height: 16px;
    border: 2px solid #222D2F;
    border-radius: 2px;
    background-color: #fff;
    z-index: 1000;
}

.material-checkbox input[type="checkbox"]:checked + .box:after {
    background-color: #d69a00;
    border-color: #d69a00;
}

.material-checkbox input[type="checkbox"]:checked + .box:before {
    transform: rotate(45deg);
    position: absolute;
    left: 20px;
    top: 3px;
    width: 5px;
    height: 12px;
    border-width: 2px;
    border-style: solid;
    border-top: 0;
    border-left: 0;
    border-color: #fff;
    content: '';
    z-index: 10000;
}

.my-events-timeline .overdue .material-checkbox input[type="checkbox"] + .box:after {
    border: 2px solid #d75c0c;
    background-color: transparent;
}

.my-events-timeline .overdue .material-checkbox input[type="checkbox"]:checked + .box:after {
    background-color: transparent;
    border-color: #d75c0c;
}

.my-events-timeline .overdue .material-checkbox input[type="checkbox"]:checked + .box:before {
    border-color: #d75c0c;
}

.dropdown-menu .divider {
    background-color: #cccccc;
}

.inline-block {
    display: inline-block;
}

input.checkbox-text[type=checkbox] {
    display:none;
}
input.checkbox-text[type=checkbox] + label
{
    cursor: pointer;
    display: inline-block;
    padding: 0px 10px;
    border: 1px solid #ccc;
    margin-left: 10px;
}
input.checkbox-text[type=checkbox]:checked + label
{
    font-weight: bold;
    color: #ffffff;
    background: #333333;
}

.no-padding {
    padding: 0;
}

.simple-link.no-padding:hover {
    padding: 0;
}

.label-none {
    background-color: #cccccc;
}

.label-black {
    background-color: #000000;
}

.wiki-tree {
    padding-left: 20px;
}

.wiki-tree ul {
    padding-left: 20px;
}

.wiki-tree .delimiter {
    height: 1px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 12px;
    list-style: none;
}

.wiki-tree .first-level {
    margin-bottom: 13px;
    list-style: none;
}

.wiki-tree a.wiki-name {
    font-size: 12px;
}

.wiki-tree a.object-name {
    font-size: 13px;
    color: #666666;
    font-weight: bold;
}

.wiki-tree a.object-name:focus,
.wiki-tree a.object-name:hover {
    text-decoration: none
}

.wiki-tree a.object-name.object-name-first-level {
    font-size: 17px;
    color: #333333;
    font-weight: bold;
    display: block;
}

.wiki-tree .indicator {
    color: #999999;
    font-size: 12px;
}

.asset-gallery-image-link:hover {
    opacity: 0.8;
}

.asset-gallery-image-link div {
    display: inline-block;
    width: 175px;
    height: 175px;
    background-size: cover;
    margin: 5px;
    border: 1px solid #cccccc;
}


.dropdown.editable-dropdown:hover .dropdown-menu {
    display: none;
    margin: 2px 0 0;
}
.dropdown.editable-dropdown.open:hover .dropdown-menu {
    display: block;
}
.dropdown.editable-dropdown {
    display: inline-block;
}
.editable-dropdown span i {
    display: none;
}
.editable-dropdown:hover span i,
.editable-dropdown.open span i {
    display: inline-block;
}
.editable-dropdown.disabled {
    pointer-events: none;
    opacity: 0.3;
}

.timeline-item .priority {
    height: 10px;
    width: 10px;
    background: transparent;
    display: inline-block;
    border-radius: 5px;
    position: relative;
    position: absolute;
    left: 37px;
    top: 5px;
}

.timeline-item .priority.priority-minor {
    background: green;
}

.timeline-item .priority.priority-major {
    background: orange;
}

.timeline-item .priority.priority-blocker {
    background: red;
}

.timeline-item .material-checkbox label {
    padding-left: 36px;
}

input.checkbox-text.company[type=checkbox] + label
{
    margin: 0;
}

.inline-editable:hover {
    background-color: rgb(231, 231, 231);
    border: 2px dotted #a2a2a2;
}

.inline-editable:empty {
    padding-left: 10px;
    padding-right: 10px;
}

.global-search {
    position: relative;
}

.global-search .search-suggestion {
    display: none;
    position: absolute;
    top: 40px;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 5px 14px;
    z-index: 100;
    box-shadow: 2px 2px 5px #ccc;
    background: #ffffff;
}

.global-search .search-suggestion li {
    margin: 4px 0px 4px;
    font-size: 12px;
}

.global-search .search-suggestion li span {
    color: #aaa;
}

.hidden-input-placeholder {
    padding: 6px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #eee;
    cursor: not-allowed;
}

.cannot-edit-inputs input,
.cannot-edit-inputs select,
.cannot-edit-inputs button {
    pointer-events: none;
}

.description-label {
    color: #999999;
}

.ql-bubble .ql-tooltip {
    z-index: 1000;
}

.ql-container {
    font-family: inherit;
    font-size: inherit;
}

.ql-editor {
    border: 1px solid #ccc3;
}

.ql-editor p {
    margin: 0 0 10px;
}

.ql-size-small {
    font-size: 0.75em;
}
.ql-size-large {
    font-size: 1.5em;
}
.ql-size-huge {
    font-size: 2.5em;
}

.inline-editor:hover {
    background: #f0f0f0;
    position: relative;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
}

.inline-editor.active:hover {
    background: transparent;
    position: relative;
    margin: 0;
    padding: 0;
    border-radius: 0;
}

.quilljs-actions {
    margin-top: 10px;
}

.quilljs-actions .btn-cancel {
    margin-left: 10px;
}

.no-break {
    white-space: nowrap;
}

.gmail_quote,
.gmail_quote blockquote {
    font-size: 12px;
}

.gmail-mail .body .show-label {
    background-color: #e8eaed;
    color: #333333;
    display: inline-block;
    font-size: 16px;
    line-height: 17px;
    padding: 0px 10px;
    border-radius: 10px;
    cursor: pointer;
}

.gmail-mail .body .show-label:hover,
.gmail-mail .body .show-label:focus {
    background-color: #c6c6c6;
}

.ql-html:after {
    content: "[src]";
}

.ql-html-editor {
    position:absolute;
    background:#fffff0;
    top: 0;
    left: 0;
    bottom: 0;
    width:100%;
    height: 100%;
    border:0;
    padding:12px;
    box-sizing:border-box;
}

.ql-snow .ql-formats::after, .ql-snow.ql-toolbar::after, .ql-snow .ql-toolbar::after {
    white-space: normal;
}

.max-width-180 {
    max-width: 180px;
}


.questionnaire-form {
    border: 1px solid #ccc;
    border-radius: 10px;
}

.questionnaire-form-header {
    padding: 20px 40px;
    background-color: #f6f1defb;
} 

.questionnaire-form-contents {
    padding: 20px 40px;
    background-color: #eaeaea;
}

.questionnaire-results {
    padding: 20px 40px;
    background-color: #fff;
}

.register-priority { line-height: 1em; padding:0px; text-align: center; vertical-align: middle; }
.register-priority span.dot {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    border:1px solid gray;
  }
.register-priority-20 span.dot { background-color: yellow }
.register-priority-30 span.dot { background-color:  orange}
.register-priority-40 span.dot { background-color: orangered }
.register-priority-50 span.dot { background-color: darkred }

.assignee-height {
    min-height: 90px;
}
.wizard {
    margin: 20px auto;
    min-height: 230px;
  }
  .wizard .nav-tabs {
    position: relative;
    margin: 40px auto;
    margin-bottom: 0;
    border: none;
  }
  .wizard > div.wizard-inner {
    position: relative;
  }
  .connecting-line {
    height: 2px;
    background: #bebebe;
    position: absolute;
    width: 60%;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 0;
  }
  .wizard .nav-tabs > li.active > a,
  .wizard .nav-tabs > li.active > a:hover,
  .wizard .nav-tabs > li.active > a:focus {
    color: #555555;
    cursor: default;
    border: 0;
    border-bottom-color: transparent;
  }
  .wizard .nav-tabs > li > a {
    border: none;
  }
  span.round-tab {
    width: 130px;
    height: 130px;
    line-height: 130px;
    display: inline-block;
    border-radius: 130px;
    border: 2px solid #c4c4c4;
    z-index: 2;
    position: absolute;
    left: 0;
    text-align: center;
    font-size: 80px;
    background-color: #fff;
  }
  span.round-tab svg {
    fill: #c2c2c2;
  }
  span.round-tab svg g {
    stroke: #c2c2c2;
  }
  span.round-tab i {
    color: #00ffff;
  }
  .tab-description {
    text-align: center;
    position: absolute;
    width: 130px;
    color: #7f7f7f;
  }
  .tab-description h3 {
    text-transform: uppercase;
    margin-bottom: 5px;
    font-size: 18px;
  }
  .tab-description h5 {
    font-weight: normal;
    font-size: 10px;
    margin-top: 4px;
    margin-bottom: 0;
  }
  .wizard li.active .tab-description {
    color: #000;
  }
  .wizard li.active span.round-tab {
    background-color: #edffdb;
    border: 2px solid #4a4a4a;
  }
  .wizard li.active span.round-tab svg {
    fill: #000;
  }
  .wizard li.active span.round-tab svg g {
    stroke: #000;
  }
  .wizard li.rejected-item span.round-tab {
    background-color: #ffdcda;
    border: 2px solid #4a4a4a;
  }
  span.round-tab:hover {
    color: #333;
    border: 2px solid #4a4a4a;
  }
  .wizard .nav-tabs > li {
    width: 25%;
  }
  .wizard li:after {
    content: " ";
    position: absolute;
    left: 46%;
    opacity: 0;
    margin: 0 auto;
    bottom: 0px;
    border: 5px solid transparent;
    border-bottom-color: #5bc0de;
    transition: 0.1s ease-in-out;
  }
  .wizard .nav-tabs > li a {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    padding: 0;
    box-shadow: -1px 0px 0 5px #fff;
  }
  .wizard .nav-tabs > li a:hover {
    background: transparent;
  }
  .round-block {
    border: 1px solid #d1d1d1;
    border-radius: 6px;
    padding: 15px 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
  }
  .round-block span.spspan {
    padding-left: 20px;
    color: #637950;
    font-weight: 600;
  }
  .round-block svg path {
    fill: #c3dcb5 !important;
  }
  .round-block.gray-bg {
    background-color: #f1f1f1;
  }
  .round-block.no-border {
    border: none;
  }
  .round-block .inline-editor {
    margin-left: 0 !important;
    transition: all 0.2s ease;
  }
  .round-block .inline-editor:hover{
    background-color: #dadada;
    margin-left: 0; 
    margin-right: 0;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  .gray-bg a {
    padding-left: 10px;
    padding-right: 10px;
  }
  .disabled-item {
    cursor: not-allowed;
  }
  .disabled-item:hover span.round-tab {
    border-color: #c4c4c4;
  }
  .order-status-10 {
    background-color: #d1d1d1;
  }
  .order-status-20 {
    background-color: #edeca8;
  }
  .order-status-30 {
    background-color: #edffdb;
  }
  .order-status-100 {
    background-color: #ffdcda;
  }
  @media (max-width: 585px) {
    .wizard {
      width: 90%;
      height: auto !important;
    }
    span.round-tab {
      font-size: 16px;
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
    .wizard .nav-tabs > li a {
      width: 50px;
      height: 50px;
      line-height: 50px;
    }
    .wizard li.active:after {
      content: " ";
      position: absolute;
      left: 35%;
    }
  }